import __C from '../../../../../../../../../src/primitives/_constant_'
import Data from '../primitives/Data'

export default {
  data: () => ({
    svg: null,
    stageCode: [],
    reqTitle: {
      summary : {
        TOTAL : 'Tag Summary - Total',
        P2W   : 'Tag Summary - Critical. Over 2 weeks from ROS',
        W2W   : 'Tag Summary - Critical. Within 2 weeks from ROS',
        NMR   : 'Tag Summary - Critical. Not the MR Item',
        NPO   : 'Tag Summary - Critical. Not the PO Item',
      }
    },
    reqData: {
      title: '',
      persistent: true,
      overlay: false,
    }
  }),
  methods: {
    setDefaultValues() {
      this.stageCode = [ ...this.eqStyle.Status ]
      this.stageCode.splice(-1)
    },
    buildRequest(title, filters) {
      let request = this.getRequestColumnProps(__C.REQUEST_ACTION.TARGET_POPUP_FREE, null, this.jsonProps)
      if(!request) return {}
  
      request.filters = filters
      request.data = { ...this.reqData }
      request.data.title = title
  
      return request
    },
  }
}