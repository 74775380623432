import * as d3 from 'd3'

export default {
  methods: {
    drawSvg() {
      this.svg = d3.select(`#${this.localId}`)
      .append('svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('version', '1.2')
      .attr('width', 271)
      .attr('height', this.legend ? 285 : this.height)
    },
    drawStageStatus() {
      let data = {
        title : '',
        shadow: false,
        source: this.summary,
        filters: { ...this.filters }
      }
      let box__ = this.svg.select('#el_stage_status_box')
      if(box__) box__.remove()

      let req = this.buildRequest(`${this.titlePrefix}${this.reqTitle.summary.TOTAL}`, data.filters)
      let d = {
        x         : 10,
        y         : 10,
        width     : 120,
        height    : 32,
        radius    : 3,
        bColor    : '#83D2F5',
        value     : data.source.TOTAL,
        valuex    : 65,
        valuey    : 18,
        valueSize : 20,
        valueColor: '#fff',
        vanchor   : 'end',
        linkable  : true,
        label     : 'Total Tags',
        labelx    : 70.5,
        labely    : 20,
        labelSize : 9,
        labelColor: '#e3f5fd',
        request   : req,
      }

      box__ = this.svg
      .append('g')
      .attr('id', 'el_stage_status_box')
      .attr('transform', `translate(0, 0)`)

      let boxbg = box__
      .append('rect')
      .attr('rx', 5).attr('ry', 5)
      .style('width', 271).style('height', this.legend ? 285 : this.height)
      .style('fill', '#fff')

      if(data.title) {
        let title__ = box__
        .append('text')
        .attr('transform', `translate(${d.x + d.width + 10}, ${d.y + d.height - 10})`)
        .style('text-anchor', 'start')
        .style('alignment-baseline', 'middle')
        .style('font-size', 12)
        .text(data.title)

        if(data.tcolor) title__.attr('fill', data.tcolor)
      }

      this.drawBoxedSummarySingle(box__, d) // draw total

      let body = []
      this.stageCode.forEach(c => {
        let prog = (
          data.source['TOTAL'] === 0 || data.source[`${c.code}_ACTUAL`] === 0 ?
          0 : 
          Number((data.source[`${c.code}_ACTUAL`] / data.source['TOTAL'] * 100).toFixed(1))
        )
        body.push({
          STATUS_CODE       : c.code,
          STAGE             : c.nameShort,
          PLAN              : data.source[`${c.code}_PLAN`],
          ACTUAL            : data.source[`${c.code}_ACTUAL`],
          DELTA             : data.source[`${c.code}_DELTA`],
          PROG              : prog,
          FILTER_STR_PLAN   : `${c.code}_PLAN <= CUTOFF`,
          FILTER_STR_ACTUAL : `${c.code}_ACTUAL IS NOT NULL`,
          TITLE_PLAN        : `${this.titlePrefix}Tag Summary - Plan on ${c.name}`,
          TITLE_ACTUAL      : `${this.titlePrefix}Tag Summary - Actual on ${c.name}`,
        })
      })

      this.tableBarProgress(box__, {
        data: {
          header: [
            { text: 'Stage' , colName: 'STAGE' , width: 52, vcolor: '#757575' },
            { text: 'Plan'  , colName: 'PLAN'  , width: 35, vcolor: '#757575', linkable: true, values: [], filterStringCol: 'FILTER_STR_PLAN'  , titleCol: 'TITLE_PLAN'   },
            { text: 'Actual', colName: 'ACTUAL', width: 35, vcolor: '#757575', linkable: true, values: [], filterStringCol: 'FILTER_STR_ACTUAL', titleCol: 'TITLE_ACTUAL' },
            { text: 'Delta' , colName: 'DELTA' , width: 35, vcolor: '#F44336' },
            { type: 'space' , width: 10 },
            { type: 'bar'   , colName: 'PROG'  , width: 80 },
          ],
          body
        },
        form: {
          x         : d.x,
          y         : d.y + d.height + 10,
          title     : { text: '', x: 28, tSize: 11, tColor: '#333' },
          lineHeight: 17,
          bColor    : '#B4E3FA', 
          sColor    : '#44A9DF', 
          tColor1   : '#053a54', 
          tColor2   : '#757575', 
          opacity   : 1,
        },
        request: req,
      })

      d.y           = d.y + d.height * 2 + 94
      d.width       = 61
      d.bColor      = '#F4F5F5'
      d.valuex      = 30
      d.valuey      = 17
      d.valueSize   = 11
      d.vanchor     = 'middle'
      d.label       = ''

      this.eqStyle.critical.forEach((c, i) => {
        req = this.buildRequest(`${this.titlePrefix}${this.reqTitle.summary[c.code]}`, { ...data.filters, CRITICAL: c.code })

        d.x           = i ? d.x + d.width + 2 : d.x
        d.value       = data.source[`${c.code}_CNT`]
        d.valueColor  = c.tcolor
        d.request     = req

        this.drawBoxedSummarySingle(box__, d)
      })

      if(this.legend) {
        let legend = box__
        .append('g')
        .attr('id', 'critical_legend')
        .attr('transform', `translate(0, ${d.y})`)
  
        this.eqStyle.critical.forEach((c, i) => {
          this.drawBoxedSummarySingle(legend, {
            x         : 10,
            y         : (16 * i) + (2*i) + 38,
            width     : 16,
            height    : 16,
            radius    : 3,
            bColor    : '#F4F5F5',
            value     : 'xx',
            valuex    : 8,
            valuey    : 9,
            valueSize : 9,
            valueColor: c.tcolor,
            vanchor   : 'middle',
            label     : c.name,
            labelx    : this.eqStyle.legend.labelx1,
            labely    : 10,
            labelSize : 9,
            class     : 'legend_critical_item'
          })
        })
      }
    },
  }
}