<template>
  <div :id="localId"></div>
</template>

<script>
// Global Functions
import gmxMiscellaneous from '../../../../includes/mixins/Miscellaneous.mixins'
import gmxDrawBoxedSummary from '../../../../includes/mixins/draw_BoxedSummary.mixin'
import gmxDrawTableBarChart from '../../../../includes/mixins/draw_TableBarChart.mixin'
import gmxEventCallHandlers from '../../../../includes/mixins/event_callHandlers.mixin'
import { SafeIdMixin } from '../../../../includes/mixins/safeid.mixin'

import dataStyles from './includes/mixins/dataStyles.mixin'
import declare from './includes/mixins/declare.mixin'
import draw from './includes/mixins/draw.mixin'

export default {
  name: "k-chart-el",
  mixins: [
    gmxMiscellaneous,
    gmxDrawBoxedSummary,
    gmxDrawTableBarChart,
    gmxEventCallHandlers,

    SafeIdMixin,
    dataStyles,
    declare,
    draw
  ],
  props: {
    id: String,
    filters: { type: Object, default: () => ({}) },
    summary: { type: Object, default: () => ({}) },
    jsonProps: { type: Array, default: () => ([]) },
  },
  watch: {
    summary: { handler() { this.draw() }, deep: true }
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.drawSvg()
    this.setDefaultValues()
    this.draw()
  },
  // watch: {
  //   summary(val) {
  //     if(!val || Object.keys(val).length === 0) return
    
  //     this.setDefaultValues()
  //     this.draw()
  //   }
  // },
  methods: {
    draw() {
      this.drawStageStatus()
    },
  }
}
</script>
