import * as d3 from 'd3'

export default {
  methods: {
    drawSvg() {
      this.svg = d3.select(`#${this.localId}`)
      .append('svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('version', '1.2')
      .attr('width', 175)
      .attr('height', 172)
    },
    drawStageStatus() {
      let data = {
        w     : 175,
        h     : 172,
        title : this.summary.LLE,
        shadow: false,
        source: this.summary,
        filters: { ...this.filters, LLE: this.summary.LLE }
      }
      let box__ = this.svg.select('#_el_stage_status_small_box')
      if(box__) box__.remove()

      let req = this.buildRequest(this.reqTitle.summary.TOTAL, data.filters)
      let d = {
        x         : 10,
        y         : 10,
        width     : 56,
        height    : 22,
        radius    : 3,
        bColor    : '#83D2F5',
        value     : data.source.TOTAL,
        valuex    : 28,
        valuey    : 13,
        valueSize : 12,
        valueColor: '#fff',
        vanchor   : 'middle',
        linkable  : true,
        label     : '',
        labelx    : 85,
        labely    : 20,
        labelSize : 9,
        labelColor: '#e3f5fd',
        request   : req,
      }

      box__ = this.svg
      .append('g')
      .attr('id', '_el_stage_status_small_box')
      .attr('transform', 'translate(0, 0)')

      let boxbg = box__
      .append('rect')
      .attr('rx', 5).attr('ry', 5)
      .style('width', data.w).style('height', data.h)
      .style('fill', '#fff')

      if(data.title) {
        let title__ = box__
        .append('text')
        .attr('transform', `translate(${d.x + d.width + 7}, ${d.y + d.height - 10})`)
        .style('text-anchor', 'start')
        .style('alignment-baseline', 'middle')
        .style('font-size', data.tsize ? data.tsize : 12)
        .text(data.title)

        if(data.tcolor) title__.attr('fill', data.tcolor)
      }
      
      this.drawBoxedSummarySingle(box__, d) // draw total

      let body = []
      this.stageCode.forEach(c => {
        let prog = (
          data.source['TOTAL'] === 0 || 
          data.source[`${c.code}_ACTUAL`] === 0 ?
          0 : 
          Number((data.source[`${c.code}_ACTUAL`] / data.source['TOTAL'] * 100).toFixed(1))
        )
        body.push({
          STAGE             : c.nameShort,
          PLAN              : data.source[`${c.code}_PLAN`],
          ACTUAL            : data.source[`${c.code}_ACTUAL`],
          DELTA             : data.source[`${c.code}_DELTA`],
          PROG              : prog,
          FILTER_STR_PLAN   : `${c.code}_PLAN <= CUTOFF`,
          FILTER_STR_ACTUAL : `${c.code}_ACTUAL IS NOT NULL`,
          TITLE_PLAN        : `Tag Summary - Plan on ${c.name}`,
          TITLE_ACTUAL      : `Tag Summary - Actual on ${c.name}`,
        })
      })

      this.tableBarQuantityOverlapped(box__, {
        data: {
          header: [
            { text: ''      , colName: 'STAGE' , width: 52, x: 48 , align: 'end'   , vcolor: '#757575', },
            { text: 'Plan'  , colName: 'PLAN'  , width: 35, x: 71 , align: 'middle', vcolor: '#757575', linkable: true, values: [], filterStringCol: 'FILTER_STR_PLAN'  , titleCol: 'TITLE_PLAN'   },
            { text: 'Actual', colName: 'ACTUAL', width: 35, x: 106, align: 'middle', vcolor: '#757575', linkable: true, values: [], filterStringCol: 'FILTER_STR_ACTUAL', titleCol: 'TITLE_ACTUAL' },
            { text: ''      , colName: 'DELTA' , width: 35, x: 128, align: 'start' , vcolor: '#F44336' },
            { type: 'bar'   , colName: 'PROG'  , width: 70, x: 54 },
          ],
          body
        },
        form: {
          x         : d.x,
          y         : d.y + d.height,
          title     : { text: '', x: 28, tSize: 11, tColor: '#333' },
          lineHeight: 16,
          bColor    : '#B4E3FA',
          sColor    : '#44A9DF',
          tColor1   : '#053a54',
          tColor2   : '#757575',
          opacity   : 1,
        },
        request: req,
      })
      
      d.y           = d.y + d.height + 108
      d.width       = 37
      d.bColor      = '#F4F5F5'
      d.valuex      = 18
      d.valuey      = 12
      d.valueSize   = 10
      d.vanchor     = 'middle'
      d.label       = ''

      this.eqStyle.critical.forEach((c, i) => {
        req = this.buildRequest(this.reqTitle.summary[c.code], { ...data.filters, CRITICAL: c.code })

        d.x           = i ? d.x + d.width + 2 : d.x
        d.value       = data.source[`${c.code}_CNT`]
        d.valueColor  = c.tcolor
        d.request     = req

        this.drawBoxedSummarySingle(box__, d)
      })
    },
  }
}