<template>
  <div :id='localId' class="k_map_board">
    <div class="k_map_board__title_container">
      <div class="k_map_board__title_box">
        <div class="title_main">{{ titles.MAIN }}</div>
        <div class="title_sub">{{ titles.SUB }}</div>
      </div>
      <!-- <div><maker-small /></div> -->
      <div class="k_map_board__title_center">{{ `${items.length} Lng./Lat. Location PO(s) Available` }}</div>
      <div>
        <kim-select
          placeholder="Input filter text"
          itemText="SELECTED_VENDOR"
          itemValue="NO"
          style="z-index: 1"
          editable
          :items="items"
          :item-columns="colProps"
          @change="onLocationChange"
        ></kim-select>
      </div>
    </div>
    <div :id="mapboardId" class="k_map_board__map_container">
    
    </div>

    <div class="k_map_board__content_wrapper_left">
      <stage-summary-large 
        class="stage_summary_main"
        :filters="filtersTrimed"
        :summary="stageSummaryMain" 
        :json-props="jsonProps" 
        @request-action="onRequestAction" 
      />
    </div>

    <div class="k_map_board__content_wrapper_right">
      <stage-summary-small 
        v-for="(summary, i) in stageSummaryLLE"
        :class="`stage_summary_sub position${i+1}`"
        :key="i"
        :filters="filtersTrimed"
        :summary="summary" 
        :json-props="jsonProps" 
        @request-action="onRequestAction" 
      />
    </div>

    <div class="location_detail_container" :class="{ show: showdetails }">
      <div class="location_detail_header">
        SUMMARY IN FLAGGED AREA
      </div>
      <button v-ripple class="button_close" title="Close" @click="onCloseContainer">
        <i class="mdi mdi-close"></i>
      </button>
      
      <div class="location_detail_list wrapper">
        <label>Suppliers & Tag Info. - {{ targetItems.length }} Tsg(s)</label>
        <div class="list_header">
          <span v-for="(col, i) in cols" class="list_header_column column" :key="i" :style="`width: ${colProps[col].width}px; text-align: ${colProps[col].align};`">{{ colProps[col].label }}</span>
        </div>
        <div class="list_body">
          <div v-for="(item, i) in targetItems" class="list_row" :key="i" @click="e => { onTargetItemClick(item) }">
            <span 
              v-for="(col, j) in cols" 
              class="list_row_column column" 
              :key="j" 
              :style="`width: ${colProps[col].width}px; text-align: ${colProps[col].align};`"
              :title="(['DESCRIPTION', 'SUPPLIER_CODE']).includes(col)?item[colProps[col].value]:''"
            >
              {{ item[col] }}
            </span>
          </div>
        </div>
      </div>
      <div class="location_detail_body">
        <div class="location_image_wrapper wrapper">
          <label>Tag Summary</label>
          <stage-summary-large 
            class="stage_summary_main"
            title-prefix="Lgn./Lat. Location - "
            :legend="false"
            :filters="filtersLgnLat"
            :summary="stageSummaryLgnLat" 
            :json-props="jsonProps" 
            @request-action="onRequestAction" 
          />
        </div>
        <div class="section_right">
          <div class="location_group_wrapper wrapper">
            <label>Group</label>
            <div class="group_box">
              <div class="group_row">
                <span class="title_name">Package</span>
                <span class="data">{{ currentItem.PACKAGE }}</span>
                <span class="title_name">Process Unit</span>
                <span class="data">{{ currentItem.PROCESS_UNIT }}</span>
              </div>
              <div class="group_row">
                <span class="title_name">Category</span>
                <span class="data">{{ currentItem.CATEGORY }}</span>
                <span class="title_name">Criticality Rating</span>
                <span class="data">{{ currentItem.CRITICALITY_RATING_LEVEL }}</span>
              </div>
            </div>
          </div>
          <div class="location_po_wrapper wrapper">
            <label>PO</label>
            <div class="group_box">
              <div class="group_row">
                <span class="title_name">Supplier Name</span>
                <span class="data">{{ currentItem.SUPPLIER_NAME }}</span>
              </div>
              <div class="group_row">
                <span class="title_name">PO No.</span>
                <span v-html="convertNewLine(currentItem.PO_NO)" class="data"></span>
              </div>
              <div class="group_row">
                <span class="title_name">Items Desc.</span>
                <span class="data">{{ currentItem.DESCRIPTION }}</span>
              </div>
              <div class="group_row">
                <span class="title_name">Buyer</span>
                <span class="data">{{ currentItem.BUYER }}</span>
              </div>
              <div class="group_row">
                <span class="title_name">Expediter</span>
                <span class="data">{{ currentItem.EXPEDITER }}</span>
              </div>
              <div class="group_row">
                <span class="title_name">Qty.</span>
                <span class="data">{{ currentItem.QTY }}</span>
              </div>
              <div class="group_row" style="cursor: pointer;" @click="onMRClicked(currentItem.MR_NO)">
                <span class="title_name">MR No.</span>
                <span class="data"><a>{{ currentItem.MR_NO }}</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="location_detail_footer"></div>
    </div>

    <j-modal-slide-component 
      v-model="modalOpen"
      :component="component"
      :filters="filters"
      :target="target"
    />

  </div>
</template>

<script>
// vendor map latest - 2023 03 23
import Vue from 'vue'

import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl'

import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import { mapState, mapMutations, mapActions } from 'vuex'
import { DBSupportService } from '@/services'

import MakerSmall from './China1VendorMap/MakerSmall'
import StageSummaryLarge from '../../../lib/jin/svgchartlib/01_Charts/99_Common/SummaryProgress/Large'
import StageSummarySmall from '../../../lib/jin/svgchartlib/01_Charts/99_Common/SummaryProgress/Small'

export default {
  name: 'k-map-board',
  components: {
    // MakerSmall
    StageSummaryLarge,
    StageSummarySmall
  },
  mixins: [
    SafeIdMixin
  ],
  props: {
    callback: { type: Object, default: () => ({}) },
  },
  data: () => ({
    map: null,
    mapboardId: '',
    queryLibService: null,
    localfilters: {},
    titles: {},
    items: [],
    defaultLngLat: {},
    stageSummaryMain: {},
    stageSummaryLgnLat: {},
    stageSummaryLLE: [],
    filterItems: [
      { colName: 'LLE'                     , label: 'LLE'           , type: 'select', options: [{code: '', name: 'All Units'}] },
      { colName: 'COUNTRY'                 , label: 'Country'       , type: 'select', options: [{code: '', name: 'All Countries'}] },
      { colName: 'SELECTED_VENDOR'         , label: 'Vendor'        , type: 'select', options: [{code: '', name: 'All Vendors'}] },
      { colName: 'PACKAGE'                 , label: 'Package'       , type: 'select', options: [{code: '', name: 'All Packages'}] },
      { colName: 'PROCESS_UNIT'            , label: 'Process Unit'  , type: 'select', options: [{code: '', name: 'All Process Unit'}] },
      { colName: 'CATEGORY'                , label: 'Category'      , type: 'select', options: [{code: '', name: 'All Categories'}] },
      { colName: 'CRITICALITY_RATING_LEVEL', label: 'Critical Level', type: 'select', options: [{code: '', name: 'All Critical Level'}] },
    ],
    targetItems: [],
    lonlatItems: [],
    currentItem: {},
    showdetails: false,
    postlocchange: false,
    selectedIdx: -1,
    instances: [],
    markers: [],
    unit: '',
    colProps: {
      SUPPLIER_CODE: {
        label: 'Supplier',
        width: 40,
        align: 'center',
        value: 'SUPPLIER_NAME'
      },
      TAG_NO: {
        label: 'Tag No.',
        width: 55,
        align: 'left'
      },
      DESCRIPTION: {
        label: 'Description',
        width: 170,
        align: 'left',
        value: 'DESCRIPTION'
      },
      CRITICALITY_RATING_LEVEL: {
        label: 'Rating',
        width: 50,
        align: 'left'
      },
      QTY: {
        label: 'Qty.',
        width: 30,
        align: 'center'
      },
      MR_NO: {
        label: 'MR',
        width: 130,
        align: 'center'
      },
      MR_PLAN: {
        label: 'DJS (F)',
        width: 50,
        align: 'center'
      },
      MR_FORECAST: {
        label: 'ROS',
        width: 50,
        align: 'center'
      },
    },
    colorStatus: {
      I   : '#eb6c58',
      II  : '#eb6c58',
      III : '#eb6c58',
      IV  : '#3381cc',
      V   : '#3381cc',
    },
    jsonProps: [
      {
        text: 'EQ PopUp',
        value: 'eqpopup',
        link: {
          action: {
            type: 'direct',
            target: 'popup',
            component: 'JModalEquipmentPopup',
          },
          values: [
            'TAG'
          ]
        }
      },
      {
        text: 'EQ PopUp',
        value: 'eqtablepopup',
        link: {
          action: {
            type: 'direct',
            target: 'tablepopup',
            component: 'JModalEquipmentPopup',
          },
          values: [
            'TAG'
          ]
        }
      },
      {
        text: 'EQ Popup Comp Free',
        value: 'popup-component-free',
        link: {
          action: {
            type: 'direct',
            target: 'popup-component-free',
            component: 'VendorMapPoItems',
          },
          values: []
        }
      }
    ],
    
    component: '',
    filters: {},
    filtersLgnLat: {},
    target: {},

    mapLoaded: false,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [
      'modalOpened',
      'popupEqOpened',
      'popupFreeOpened', 
      'popupFreeCallback',
    ]),
    ...mapState(__C.STORE_NAMESPACE.CUSTOM_COMPONENT, [
      'filteredValues'
    ]),

    cols() { return Object.keys(this.colProps) },
    filtersTrimed() {
      let trimed = {}

      Object.keys(this.filteredValues).forEach(k => { 
        if(!this.filteredValues[k]) return
        trimed[k] = this.filteredValues[k]
      })

      return trimed
    },

    modalOpen: {
      get() { return this.modalOpened },
      set(val) { this.setModalOpened(val) },
    },
  },
  watch: {
    popupFreeCallback(d) {
      this.onLocationChange(d.NO)
    }
  },
  created() {
    this.localId = this.id || 'k-map-board__' + this.safeId('')
    this.mapboardId = `${this.localId}__mapboard`

    this.queryLibService = new DBSupportService()
    this.setChild(__C.STORE_NAMESPACE_KEY.CUSTOM_COMPONENT)
    this.setPagecallFunc(this.run)
  },
  mounted() {
    this.queryLibService.getSqlQueryResult({
      idx: 0, 
      name: 'China1 Vendor Map Titles',
      filters: ''
    }).then(t => {
      this.titles = t ? t[0] : {}
    })
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APPLICATION, [
      'setPagecallFunc'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild',
      'setSharedData',
      'setModalOpened',
      'setPopupFreeOpened',
      'setPopupEqOpened',
    ]),

    ...mapMutations(__C.STORE_NAMESPACE.CUSTOM_COMPONENT, [
      'setFnRequest',
      'setFilterOptions',
      'setFilterChains',
    ]),
    ...mapActions(__C.STORE_NAMESPACE.CUSTOM_COMPONENT, [
      'initService',
      'updateFilteredValues',
    ]),

    onRequestAction(request) {
      let action___ = request.action
      
      if([
        __C.REQUEST_ACTION.TARGET_POPUP,
        __C.REQUEST_ACTION.TARGET_ROOM_POPUP
      ].includes(action___.target)) {
        if(request.shared) this.setSharedData(request.shared)
        if(action___.purpose == 'equipment-search' && !this.popupEqOpened) return

        this.setPopupEqOpened({
          open: true,
          queryid: action___.id,
          filters: request.filters,
          data: request.data,
        })
        return
      }
      if(__C.REQUEST_ACTION.TARGET_POPUP_FREE == action___.target) {
        if(request.shared) {
          this.setSharedData(request.shared)
          return
        }
        if(action___.purpose == 'equipment-search' && !this.popupFreeOpened) return

        this.setPopupFreeOpened({
          open: true,
          component: action___.component,
          queryid: action___.id,
          queryname: action___.no,
          filters: request.filters,
          filterString: request.iFilters.filterString,
          data: request.data,
        })
        return
      }

      // For the Slide-Modal process
      this.setModalOpened(true)
      this.component = request.action.component
      this.target = {
        code: __C.PAGE_COMPONENT.TYPE_MODAL,
        type: '',
        id: request.action.id,
        no: request.action.no,
      }
      this.filters = {
        // filters: request.dataType == 'summary' ? (this.filteredValuesTrimed || {}) : request.filters,
        filters: { ...request.filters, ...this.filteredValuesTrimed },
        iFilters: { ...request.iFilters, inputFilter: this.inputFilter }
      }

      console.log(`[USER: #DEBUG] Target  [0]: ${this.component} ${JSON.stringify(this.target)}`)
      console.log(`[USER: #DEBUG] Filters [1]: ${JSON.stringify(this.filters.filters)}`)
      console.log(`[USER: #DEBUG] iFilters[2]: ${JSON.stringify(this.filters.iFilters)}`)
    },
    onMRClicked(mrno) {
      if(!mrno) return

      this.onRequestAction({
        action: {
          target: 'slide-modal',
          component: 'ServiceDashboard',
          id: 152,
          no: 'MR Detail Popup'
        },
        filters: { MR_NO: mrno }
      })
    },
    onMapMoveStart(e) {
      this.showdetails = false
    },
    onMapMoved(e) {
      if(this.postlocchange) {
        this.selectMarker(this.currentItem) 
        this.postlocchange = false
      }
    },
    onLocationChange(v) {
      this.showdetails = false

      let item = this.items.find(item => item.NO == v)
      if(!item) return

      this.map.flyTo({
        center: [item.ORIGIN_LONGITUDE, item.ORIGIN_LATITUDE],
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })

      this.currentItem = item
      this.postlocchange = true
    },
    onTargetItemClick(item) {
      this.currentItem = item
      this.onRequestAction({
        action: {
          type: 'direct',
          target: 'popup',
          component: 'JModalEquipmentPopup',
        },
        filters: { TAG: item.TAG_NO, PO_NO: item.PO_NO },
        data: {
          title: '',
          sender: 'Vendor Map',
          persistent: true,
          overlay: false,
        }
      })
    },
    onCloseContainer() {
      this.closeContainer()
    },

    loadMap() {
      mapboxgl.accessToken = 'pk.eyJ1IjoiamdzMjUxMzYwMSIsImEiOiJjbDdtd3hkZGwxdnhxM3VzYXQwNjVyajVhIn0.lQostCoup-azYrb6NVB6Pg'
      this.map = new mapboxgl.Map({
        container: this.mapboardId,
        style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: [this.defaultLngLat.ORIGIN_LONGITUDE, this.defaultLngLat.ORIGIN_LATITUDE], // starting position [lng, lat]
        zoom: 2.5, // starting zoom
        projection: 'globe', // display the map as a 3D globe
        attributionControl: false,
      })
      this.map.on('style.load', () => {
        // Set the default atmosphere style
        this.map.setFog({})
      })
      this.map.on('zoom', (e) => { this.$emit('zoom', this.map.getZoom()) })
      this.map.on('moveend', this.onMapMoved)
      this.map.on('movestart', this.onMapMoveStart)
      // this.map.addControl(new mapboxgl.FullscreenControl())

      this.mapLoaded = true

      setTimeout(() => {
        let el = document.querySelector(`#${this.mapboardId} .mapboxgl-ctrl-logo`)
        el.style.display = 'none'
      })
    },
    loadData() {
      let conditions__ = []
      Object.keys(this.filteredValues).forEach(k => { 
        if(!this.filteredValues[k]) return
        conditions__.push(`${k} = '${this.filteredValues[k]}'`) 
      })

      // console.log(JSON.stringify({
      //   idx: 0, 
      //   name: 'China1 Vendor Map Main',
      //   filterType: 'json',
      //   filters: JSON.stringify(this.filtersTrimed),
      //   filterString: ''
      // }))

      this.queryLibService.getSqlQueryResult({
        idx: 0, 
        name: 'China1 Vendor Map Main',
        filterType: 'json',
        filters: JSON.stringify(this.filtersTrimed),
        filterString: ''
      }).then(res => {
        if(res) {
          this.items = res[0].poList ? JSON.parse(res[0].poList) : []
          this.defaultLngLat = res[0].defaultLngLat ? JSON.parse(res[0].defaultLngLat)[0] : {}
          this.stageSummaryMain = res[0].stageSummaryMain ? JSON.parse(res[0].stageSummaryMain) : {}
          this.stageSummaryLLE = res[0].stageSummaryLLE ? JSON.parse(res[0].stageSummaryLLE) : {}
        } else {
          this.items = []
          this.stageSummaryMain = {}
          this.stageSummaryLLE = []
        }

        this.queryLibService.getSqlQueryResult({
          idx: 0, 
          name: 'China1 Vendor Map LonLat & Count',
          filterType: 'json',
          filters: JSON.stringify(this.filtersTrimed),
          filterString: ''
        }).then(lonlats => {
          this.lonlatItems = lonlats || []
       
          if(!this.mapLoaded) this.loadMap()
          this.setMarker()
        })
      })
    },

    setLgnLatSummary(coodinate) {
      let filteres__ = JSON.stringify({ ...this.filtersTrimed, ...coodinate })
      if(JSON.stringify(this.filtersLgnLat) == filteres__) return

      this.filtersLgnLat = { ...this.filtersTrimed, ...coodinate }

      this.queryLibService.getSqlQueryResult({
        idx: 0, 
        name: 'China1 Vendor Map Summary LngLat.',
        filterType: 'json',
        filters: filteres__,
        filterString: ''
      }).then(lonlats => {
        this.stageSummaryLgnLat = lonlats ? lonlats[0] : {}
      })
    },
    selectMarker(item) {
      this.targetItems = this.items.filter(item_ => (
        item_.ORIGIN_LONGITUDE.toFixed(2) == Number(item.ORIGIN_LONGITUDE).toFixed(2) &&
        item_.ORIGIN_LATITUDE.toFixed(2) == Number(item.ORIGIN_LATITUDE).toFixed(2)
      ))

      if(this.targetItems.length) this.currentItem = this.targetItems[0]
      else this.currentItem = {}

      let idx = this.lonlatItems.findIndex(item_ => (
        Number(item_.ORIGIN_LONGITUDE).toFixed(2) == Number(item.ORIGIN_LONGITUDE).toFixed(2) &&
        Number(item_.ORIGIN_LATITUDE).toFixed(2) == Number(item.ORIGIN_LATITUDE).toFixed(2)
      ))
      if(this.selectedIdx >= 0) this.instances[this.selectedIdx].selected = false
      this.instances[idx].selected = true
      this.selectedIdx = idx

      let el = document.querySelector(`#${this.localId} .location_detail_container`)
      // el.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`
      // el.style.top = `${e.layerY}px`
      // el.style.left = `${e.layerX+20}px`
      let maprect = document.querySelector(`#${this.mapboardId}`).getBoundingClientRect()
      let rect = this.instances[idx].$el.getBoundingClientRect()
      let offsetY = maprect.y - 30
      let offsetX = maprect.x - 50

      el.style.top = `${rect.y - offsetY}px`
      el.style.left = `${rect.x - offsetX}px`

      this.showdetails = true
      this.setLgnLatSummary({
        ORIGIN_LONGITUDE: Number(item.ORIGIN_LONGITUDE),
        ORIGIN_LATITUDE: Number(item.ORIGIN_LATITUDE),
      })
    },
    setMarker() {
      this.showdetails = false
      this.selectedIdx = -1
      this.currentItem = {}
      this.instances = []
      this.markers.forEach(marker => { marker.remove() })
      this.markers = []

      this.lonlatItems.forEach(item => {
        let marker = Vue.extend(MakerSmall)
        // let instance = new marker()
        let instance = new marker({
          propsData: { 
            // color: this.colorStatus[item.Criticality_Rating],
            count: parseInt(item.COUNT_)
          }
        })
        instance.$on('mouseover', () => { this.selectMarker(item) })
        instance.$mount()
        
        this.instances.push(instance)
        this.markers.push(
          new mapboxgl.Marker({
            anchor: 'bottom',
            element: instance.$el
          })
          .setLngLat([item.ORIGIN_LONGITUDE, item.ORIGIN_LATITUDE])
          .addTo(this.map)
        )
      })
    },
    setFilters(type) {
      //   filterChains: [
      //    [ ...filterOptions ],
      //    [ ...filterOptions ],
      //    ...
      //   ],
      //   filterOptions: [{      // LNB Filtering Options
      //     colName: String,     // Physical Column Name
      //     label: String,       // Displaying Column Name
      //     type: String,        // Component type
      //     options: Array       // Component Values
      //   }],

      // '{"code":"","name":"All ', @label_, '"}'

      this.queryLibService.getSqlQueryResult({
        idx: 0, 
        name: 'China1 Vendor Map Filter Chain',
        filterType: 'json',
        filters: JSON.stringify(this.filtersTrimed),
        filterString: ''
      }).then(res => {
        let filters__ = res[0]
        let chains__ = this.filterItems.map(item => ({
          ...item,
          options: [
            ...item.options,
            ...JSON.parse(filters__[item.colName]).map(item_ => ({ code: item_[item.colName], name: item_[item.colName] }))
          ]
        }))

        this.setFilterChains([chains__])
      })
    },
    fnRequest(type) {
      return new Promise((resolve) => {
        this.setFilters(type)
        this.loadData()
        resolve(true)
      })
    },
    convertNewLine(text) {
      if(!text) return ''
      return text.replace('|', '<br />')
    },
    closeContainer() {
      this.showdetails = false
    },
    
    run() {
      return this.initService().then((navId) => {
        this.setFnRequest(this.fnRequest)
        this.setFilters('init') 
        this.loadData()

        return new Promise((resolve) => {
          resolve(navId)
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.k_map_board {
  position: relative;
  height: 100%;

  .k_map_board__content_wrapper_left {
    position: absolute;
    top: 3.5rem;
    left: 0;

    .stage_summary_main {
      position: absolute;
      top: 1rem;
      left: 1rem;
    }
  }
  .k_map_board__content_wrapper_right {
    position: absolute;
    top: 3.5rem;
    right: 0;

    .stage_summary_sub {
      position: absolute;

      &.position1 {
        top: 1rem;
        right: 1rem;
      }
      &.position2 {
        top: 1rem;
        right: 19rem;
      }
      &.position3 {
        top: 18.7rem;
        right: 1rem;
      }
    }
  }

  .location_detail_container {
    position: absolute;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);

    width: 72.3rem;
    padding: 0 1.5rem;
    border-radius: 5px;
    background-color: #fff;

    display: none;

    &.show {
      display: block;
    }

    .button_close {
      position: absolute;
      top: 1rem;
      right: 1.5rem;

      .mdi-close {
        font-size: 1.4rem;
      }
    }

    .wrapper {
      position: relative;

      padding: 1rem;
      border: 1px solid rgb(229, 229, 229);
      border-radius: 3px;

      label {
        position: absolute;
        top: -.6rem;
        left: .5rem;

        line-height: 1.2rem;
        padding: 0 1rem;
        background-color: #fff;
      }
    }

    .location_detail_header {
      padding: 1rem 0 2rem 0;
    }
    .location_detail_list {
      position: relative;
      margin-bottom: 1rem;

      span {
        display: block;

        white-space: nowrap;
        margin-right: 1rem;

        // pointer-events: none;
        cursor: default;
        overflow: hidden;
      }

      .list_header {
        position: absolute;
        top: 0;

        display: flex;
        padding: 1rem 0 .3rem 0;
        border-bottom: 1px solid rgb(229, 229, 229); 

        .list_header_column {
          color: #999;
        }
      }
      .list_body {
        max-height: 8rem;
        margin-top: 1.9rem;
        overflow: auto;

        .list_row {
          display: flex;
          padding: .1rem 0;
        }
        .list_row:hover {
          background-color: #eee;
        }
      }
    }
    .location_detail_body {
      display: flex;

      .section_right {
        margin-left: .8rem;

        .group_row {
          display: flex;
          align-items: flex-start;

          span {
            display: block;
            pointer-events: none;

            &.title_name {
              color: #9e9e9e;
              width: 8rem;
              text-align: right;
              margin-right: .5rem;
              line-height: 1.8rem;
            }
          }
        }

        .location_group_wrapper {
          margin-bottom: 1rem;

          .data {
            width: 10rem;
            line-height: 1.8rem;
          }
        }

        .location_po_wrapper {
          height: 16.3rem;

          .data {
            width: 28rem;
          }
        }
      }

      .location_image_wrapper {
        height: 100%;

        .image_box {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 25rem;
          height: 20rem;

          overflow: hidden;

          img {
            border-radius: 3px;
          }
        }
      }
    }

    .location_detail_footer {
      height: 1.5rem;
    }
  }

  .k_map_board__title_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    margin-bottom: .5rem;

    .k_map_board__title_box {
      display: flex;

      .title_main {
        color: #fff;
        font-size: 1.1rem;
      }
      .title_sub {
        margin-left: 1.5rem;
        color: rgb(187, 222, 251);
        font-size: 1.1rem;
      }
    }

    .k_map_board__title_center {
      color: #fff;
      font-size: 1.1rem;
    }
  }

  .k_map_board__map_container {
    height: calc(100% - 3.3rem);
  }
}
</style>
