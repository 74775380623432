<template>
  <div 
    :id='localId' 
    class="map_marker_small"
    @mouseover="onmouseover"
    @mouseenter="onmouseover"
    @mouseout="onmouseout"
    @mouseleave="onmouseout"
    @click="onclick"
  >
    <div class="wrapper">
      <div class="marker_box"><v-icon class="map_marker_small_icon" :class="{selected: selected}">mdi-map-marker</v-icon></div>
      <div class="count_box" :title="`${count} POs`">{{ count }}</div>
    </div>
  </div> 
</template>

<script>
import { SafeIdMixin } from '@/mixins/safeid.mixin'

export default {
  name: 'map-marker-small',
  mixins: [
    SafeIdMixin
  ],
  props: {
    selected: {
      type: Boolean,
      default: () => false
    },
    color: {
      type: String,
      default: () => '#eb6c58'
    },
    count: {
      type: Number,
      default: () => 0
    },
    value: null,
  },
  data: () => ({
    over: false
  }),
  created() {
    this.localId = this.id || 'k-map-board__' + this.safeId('')
  },
  methods: {
    onmouseover(e) { this.$emit('mouseover', e)},
    onmouseout(e) { this.$emit('mouseout', e)},
    onclick(e) { this.$emit('click', e)}
  },
}
</script>

<style lang="stylus" scoped>
  .map_marker_small {
    cursor: default;

    .wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;

      .marker_box {
        margin: -.4rem -1rem -.7rem -1rem;

        .map_marker_small_icon {
          font-size: 5rem;
          color: #eb6c58;

          &.selected {
            color: #9cba54;
          }
        }
        .map_marker_small_icon:hover {
          color: #3381cc;
        }
      }
      .logo_box {
        position: absolute;
        top: .3rem;
        pointer-events: none;

        img {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
        }
      }

      .count_box {
        position: absolute;
        top: .4rem;

        display: flex;
        justify-content: center;
        align-items: center;
        
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, .75);

        color: rgb(255, 255, 255);
        font-size: 1.1rem;
        
        pointer-events: none;
      }
    }
  }

</style>